// MarioGame.tsx

import './Mario.css';
import React, { useEffect, useRef } from 'react';

const MarioGame: React.FC = () => {
  const playerRef = useRef<HTMLDivElement | null>(null);

  interface Powerup {
    audio: HTMLAudioElement;
    play: () => void;
  }

  const powerup: Powerup = {
    audio: new Audio('http://themushroomkingdom.net/sounds/wav/smb/smb_powerup.wav'),
    play: function () {
      this.audio.currentTime = 0;
      this.audio.play();
    },
  };

  // Update the character's location
  // Actualizamos la ubicación del personaje
function updateElement(element: HTMLElement, incx: number, incy: number) {
    var x = Number(element.getAttribute('data-x')) + incx;
    var y = Number(element.getAttribute('data-y')) + incy;
  
    // Check the boundaries to prevent walking off the screen
    const boundaryX = window.innerWidth - 100; // Adjust the value based on your requirements
    const boundaryY = 200; 
    var maxX = boundaryX - element.clientWidth; // Adjust for the width of Mario
    var maxY = boundaryY - element.clientHeight; // Adjust for the height of Mario
  
    if (x < 0) {
      x = 0;
    } else if (x > maxX) {
      x = maxX;
    }
  
    if (y < 0) {
      y = 0;
    } else if (y > maxY) {
      y = maxY;
    }
  
    element.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  
    if (element.classList.contains('mirror')) element.style.transform += ' scaleX(-1)';
    if (element.classList.contains('big')) element.style.transform += ' scale(2)';
  
    // Update HTML
    element.setAttribute('data-x', x.toString());
    element.setAttribute('data-y', y.toString());
  }
  

  // When the user presses a key
  const handleKeyDown = (e: KeyboardEvent) => {
    console.log(e);

    const speed = 10;

    // Right arrow key
    if (e.key === "ArrowRight" || e.key === "d") {
      playerRef.current?.classList.add('caminar');
      playerRef.current?.classList.remove('mirror');
      updateElement(playerRef.current!, +speed, 0);
    }
    // Left arrow key
    else if (e.key === "ArrowLeft" || e.key === "a") {
      playerRef.current?.classList.add('caminar');
      playerRef.current?.classList.add('mirror');
      updateElement(playerRef.current!, -speed, 0);
    }

    if (e.key === "u") {
      playerRef.current?.classList.toggle('big');
      powerup.play(); // fix network lag sound
      updateElement(playerRef.current!, 0, 0);
    }
  };

  // When the user releases a key
  const handleKeyUp = () => {
    // Remove the 'caminar' class to stop the animation
    // and freeze on the first frame, as when there is no animation.
    playerRef.current?.classList.remove('caminar');
  };

  useEffect(() => {
    // Initialization
    updateElement(playerRef.current!, 0, 0);

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  // eslint-disable-next-line
  }, [[handleKeyDown]]);

  return (
    <div
      id="mario"
      ref={playerRef}
      style={{
        width: '58px',
        height: '100px',
        border: '0px solid #000',
        backgroundImage: 'url(https://cdn.rawgit.com/ManzDev/cursos-assets/gh-pages/css3/mario-sprite.png)',
      }}
    ></div>
  );
};

export default MarioGame;
