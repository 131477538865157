import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Bio from './pages/bio';
import Home from './pages/home';
import Musings from './pages/musings';
import Travel from './pages/travel';
import Random from './pages/random';
import Article from './components/Article';
import NotFound from './pages/not_found';

function App() {  
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Navbar/>
                <Routes>
                  <Route path="/" element= { <div className="centered-container"> <Home/> </div> }/>
                  <Route path="/bio" element= { <div className="left-container"> <Bio/> </div> }/>
                  <Route path="/musings" element= { <div className="left-container"> <Musings/> </div> }/>
                  <Route path="/musings/:postId/*" element= { <div className="left-container"> <Article match={{ params: { postId: ''}}}/> </div> }/>
                  <Route path="/travel" element= { <div className="left-container"> <Travel/> </div> }/>
                  <Route path="/random" element= { <div className="left-container"> <Random/> </div> }/>
                  <Route path='*' element={ <div className="left-container"> <NotFound/> </div> } />
                </Routes>
          <Footer />
        </header>
      </Router>
    </div>
  );
}

export default App;
