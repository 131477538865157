// Article.tsx

import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Markdown from "react-markdown"
import postList from "../content/posts.json"
import './Article.css';
import NotFound from '../pages/not_found';

interface ArticleProps {
  match: {
    params: {
      postId: string;
    };
  };
}

interface PostModel {
    id?: string;
    title?: string;
    date?: string;
    author?: string;
    content?: string;
  }

const Article: React.FC<ArticleProps> = ({ match }) => {
  const { postId } = useParams<{ postId: string }>();

  const fetchedPost: PostModel = {content: ''};
  let postExists = false
    postList.forEach((post, i) => {
        if (postId === post.id) {
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.author = post.author ? post.author : "No author given"
            fetchedPost.content = post.content ? post.content : "No content given"
            postExists = true
        }
    })

    if (!postExists) {
      return <NotFound/>
    }

  return (
          <div>
            <Helmet>
              <title>{fetchedPost.title}</title>
            </Helmet>
            <h1 style={{ marginBottom: '0rem' }}>{fetchedPost.title}</h1>
            <small>{fetchedPost.date}</small>
            <Markdown className="markdown">{fetchedPost.content}</Markdown>
      </div>
  );
};

export default Article;
