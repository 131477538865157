// Footer.tsx

import React from 'react';
import './Footer.css';
import SVGIcon from "./SVGIcon"
import { ReactComponent as LinkedInIconSVG } from "../svg/linkedin.svg";
import { ReactComponent as GithubIconSVG } from "../svg/github.svg";
import { ReactComponent as InstagramIconSVG } from "../svg/instagram.svg";
import { ReactComponent as SoundcloudIconSVG } from "../svg/soundcloud.svg";
import { ReactComponent as SpotifyIconSVG } from "../svg/spotify.svg";

const Footer: React.FC = () => {
  const currentYear: string = new Date().getFullYear().toString();
  
  return (
      <footer className="footer">
        <a href="https://www.instagram.com/prinstabites" target="_blank" rel="noopener noreferrer">
          <SVGIcon svgComponent={InstagramIconSVG} size={20} />
        </a>
        <a href="https://www.linkedin.com/in/bpmusokelubega" target="_blank" rel="noopener noreferrer">
          <SVGIcon svgComponent={LinkedInIconSVG} size={20} />
        </a>
        <a href="https://github.com/bprinsta" target="_blank" rel="noopener noreferrer">
        <SVGIcon svgComponent={GithubIconSVG} size={20} />
        </a>
        <a href="https://soundcloud.com/prinsta" target="_blank" rel="noopener noreferrer">
        <SVGIcon svgComponent={SoundcloudIconSVG} size={20} />
        </a>
        <a href="https://open.spotify.com/artist/14xQyIB0btggMQQDl2aCxj?si=Y_DpjdVZReiAT2V4lG8ltA" target="_blank" rel="noopener noreferrer">
          <SVGIcon svgComponent={SpotifyIconSVG} size={20} />
        </a>
        <p>&copy; {currentYear} Benjamin Musoke-Lubega</p>
      </footer>
  );
};

export default Footer;
